import React, { useRef, useState } from 'react';
import { Card, Form, Button, Alert } from 'react-bootstrap';
import { useAuth } from '../../contexts/AuthContext';
import { Link, useHistory } from 'react-router-dom';
import CenteredContainer from './CenteredContainer';
import Navbar from '../notes/Navbar';

export default function UpdateProfile() {
    const emailRef = useRef();
    const passwordRef = useRef();
    const confirmPasswordRef = useRef();
    const displayNameRef = useRef();
    const { currentUser, updateEmail, updatePassword, updateProfile } = useAuth();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    function handleSubmit(e) {
        e.preventDefault();

        if(passwordRef.current.value !== confirmPasswordRef.current.value) {
            return setError('Passwords to not match.');
        }

        setLoading(true);
        setError('');
        const promises = [];
        if(emailRef.current.value !== currentUser.email) {
            promises.push(updateEmail(emailRef.current.value));
        }
        if(passwordRef.current.value) {
            promises.push(updatePassword(passwordRef.current.value));
        }
        if(displayNameRef.current.value) {
            promises.push(updateProfile(displayNameRef.current.value));
        }

        Promise.all(promises).then(() => {
            history.push('/profile');
        }).catch(() =>{
            setError('Failed to update account');
        }).finally(() => {
            setLoading(false);
        })
    }

    return (
        <>
            <Navbar/>
            <CenteredContainer>
                <Card>
                    <Card.Body>
                        <h2 className="text-center mb-4">Update Profile</h2>
                        { error && <Alert variant="danger">{error}</Alert> }
                        <Form onSubmit={handleSubmit}>
                            <Form.Group id="name">
                                <Form.Label>Name</Form.Label>
                                <Form.Control ref={displayNameRef} required defaultValue={currentUser.displayName}/>
                            </Form.Group>
                            <Form.Group id="email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" ref={emailRef} required defaultValue={currentUser.email}/>
                            </Form.Group>
                            <Form.Group id="password">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password" ref={passwordRef} placeholder="Leave blank to keep the same"/>
                            </Form.Group>
                            <Form.Group id="confirm-password">
                                <Form.Label>Confirm Password</Form.Label>
                                <Form.Control type="password" ref={confirmPasswordRef} placeholder="Leave blank to keep the same"/>
                            </Form.Group>
                            <Button disabled={loading} className="w-100" type="submit">
                                Update
                            </Button>
                        </Form>
                    </Card.Body>
                </Card>
                <div className="w-100 text-center mt-2">
                    <Link to="/profile">Cancel</Link>
                </div>
            </CenteredContainer>
        </>
    )
}
