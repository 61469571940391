import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { formatDate } from '../../helpers';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { useNotes } from '../../contexts/NotesContext';

export default function Note({note}) {
    const { removeNote } = useNotes();
    const [open, setOpen] = useState(false);

    function handleRemoveNote(id) {
        removeNote(id);
    }

    function openModal() {
        setOpen(true);
    }

    function closeModal (){
        setOpen(false);
    }

    return (
        <>
            <div className="d-flex justify-content-between">
                <Button variant="light" className="w-100 text-truncate"
                    to={{
                        pathname: `/note/${note.id}`
                    }} as={Link}>
                    <div>{note.name}</div>
                    <small>{formatDate(note.updated.seconds)}</small><br></br>
                    <small>{note.content}</small>
                </Button>
                <Button onClick={openModal} className="pr-1" variant="danger">
                    <FontAwesomeIcon icon={faTrash} className="mr-2"/>
                </Button>
            </div>
            <Modal show={open} onHide={closeModal} size="sm">
                <Modal.Header closeButton>
                    <Modal.Title>Delete Note</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <p>Are you sure?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>Cancel</Button>
                    <Button variant="danger" onClick={() => handleRemoveNote(note.id)}>Delete</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
