import React, { useContext, useState, useEffect } from 'react';
import { database } from "../firebase";
import { useAuth } from "./AuthContext";

const NotesContext = React.createContext();

export function useNotes() {
    return useContext(NotesContext);
}

export function NotesProvider({ children }) {
    const [loading, setLoading] = useState(true);
    const { currentUser } = useAuth();
    const [notes, setNotes] = useState([]);

    useEffect(() => {
        if(currentUser) {
            setLoading(true);
            loadNotes(currentUser.uid);
        }
        else {
            setLoading(false);
        }
    }, [currentUser]);

    function loadNotes(userId) {
        return database.notes
        .where("userId", "==", userId)
        .orderBy("updated", "desc")
        .onSnapshot(snapshot => {
            setLoading(false);
            setNotes(snapshot.docs.map(database.formatDoc));
        });

    }

    function updateNote(noteId, name, content) {
        database.notes
            .where("userId", "==", currentUser.uid)
            .where("id", "==", noteId)
            .get()
            .then(existingNotes => {
                const existingNote = existingNotes.docs[0];
                const currentTime = database.getCurrentTimestamp();
                if (existingNote) {
                    existingNote.ref.update({ name: name, content: content, updated: currentTime })
                    .then(() => {
                        loadNotes(currentUser.uid);
                    });
                }
                else {
                    console.log("no note found can't update");
                }
            });
    }

    function addNote(noteId, name, content) {
        const currentTime = database.getCurrentTimestamp();
        database.notes.add({
            id: noteId,
            name: name,
            created: currentTime,
            updated: currentTime,
            userId: currentUser.uid,
            content: content
        }).then(() => {
            loadNotes(currentUser.uid);
        });
    }

    function removeNote(noteId) {
        database.notes
            .where("userId", "==", currentUser.uid)
            .where("id", "==", noteId)
            .get()
            .then(existingNotes => {
                const existingNote = existingNotes.docs[0];
                if (existingNote) {
                    existingNote.ref.delete()
                    .then(() => {
                        loadNotes(currentUser.uid);
                    });
                }
                else {
                    console.log("no note found can't delete");
                }
            });

    }

    const value = {
        notes,
        updateNote,
        addNote,
        removeNote
    };

    return (
        <NotesContext.Provider value={value}>
            {!loading && children}
        </NotesContext.Provider>
    )
}
