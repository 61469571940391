import React from 'react';
import Signup from './authentication/Signup';
import { AuthProvider } from '../contexts/AuthContext';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Profile from './authentication/Profile';
import Login from './authentication/Login';
import PrivateRoute from './authentication/PrivateRoute';
import ForgotPassword from './authentication/ForgotPassword';
import UpdateProfile from './authentication/UpdateProfile';
import Dashboard from './notes/Dashboard';
import NoteDetails from './notes/NoteDetails';
import { NotesProvider } from '../contexts/NotesContext';

function App() {
  return (
    <>
      <Router>
        <AuthProvider>
            <NotesProvider>
              <Switch>
                <PrivateRoute exact path="/" component={Dashboard} />
                <PrivateRoute exact path="/note/:noteId" component={NoteDetails} />
                <PrivateRoute path="/profile" component={Profile} />
                <PrivateRoute path="/update-profile" component={UpdateProfile} />
                <Route path="/signup" component={Signup} />
                <Route path="/login" component={Login} />
                <Route path="/forgot-password" component={ForgotPassword} />
              </Switch>
            </NotesProvider>
        </AuthProvider>
      </Router>
    </>
  );
}

export default App;
