import React from 'react';
import Navbar from './Navbar';
import Note from './Note';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { v4 as uuidV4 } from "uuid";
import { useHistory } from 'react-router-dom';
import { useNotes } from '../../contexts/NotesContext';

export default function Dashboard() {
    const history = useHistory();
    const { notes } = useNotes();

    function addNote() {
        const id = uuidV4();
        history.push(`/note/${id}`);
    }

    return (
        <>
            <Navbar/>
            <Button variant="primary" to="/" className="mt-3 ml-4" onClick={addNote}>
                <FontAwesomeIcon icon={faPlus} className="mr-2" />
                Add Note
            </Button>
            <div className="d-flex flex-wrap">
                {notes && notes.map(note => (
                    <div
                        key={note.id}
                        className="p-2 w-100"
                    >
                        <Note note={note} />
                    </div>
                ))}
            </div>
        </>
    )
}
