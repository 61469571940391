export function formatDate(timestamp) {
    if(!timestamp) {
        return '';
    }
    let date = new Date(timestamp * 1000);
    let hours = date.getHours();
    let minutes = "0" + date.getMinutes();
    let seconds = "0" + date.getSeconds();
    let month = date.getMonth();
    let day = date.getDate();
    let year = date.getFullYear();
    let formattedTime = month + "/" + day + "/" + year + ' ' + hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
    
    return formattedTime;

}