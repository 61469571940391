import React, { useState, useRef, useEffect } from 'react';
import Navbar from './Navbar';
import { useParams, Link } from "react-router-dom";
import { Card, Button, Form, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faSave } from "@fortawesome/free-solid-svg-icons";
import { formatDate } from '../../helpers';
import { useNotes } from '../../contexts/NotesContext';

export default function NoteDetails() {
    const { noteId } = useParams()
    const nameRef = useRef();
    const contentRef = useRef();
    const [message, setMessage] = useState('');
    const [currentNote, setCurrentNote] = useState({name: null, content: null, updated: null});
    const { notes, updateNote, addNote } = useNotes();
    const [error, setError] = useState('');

    useEffect(() => {
        const foundNote = notes.find(note => note.id === noteId);
        if(foundNote) {
            setCurrentNote(foundNote);
        }
    }, [noteId, notes]);

    async function saveNote() {
        setMessage('');
        setError('');
        if(!nameRef.current.value || !contentRef.current.value) {
            setError("Title and notes are required");
            return;
        }
        if(currentNote.name) {
            await updateNote(noteId, nameRef.current.value, contentRef.current.value);
        }
        else {
            await addNote(noteId, nameRef.current.value, contentRef.current.value);
        }
        setMessage("Note saved!");
    }
    
    return (
        <>
            <Navbar/>
            <Card>
                <Card.Body>
                    <Form>
                        <Form.Group controlId="noteName">
                            <Form.Control placeholder="Title" defaultValue={currentNote.name} ref={nameRef} required/>
                        </Form.Group>
                        <Form.Group controlId="noteContent">
                            <Form.Control placeholder="Notes" as="textarea" rows="10" name="content" ref={contentRef} defaultValue={currentNote.content} required/>
                        </Form.Group>
                        <Form.Label className="mb-2 text-muted">Last updated: { currentNote.updated && formatDate(currentNote.updated.seconds)}</Form.Label>
                    </Form>
                    { message && <Alert variant="success">{message}</Alert> }
                    { error && <Alert variant="danger">{error}</Alert> }
                </Card.Body>
            </Card>
            <Button as={Link} variant="secondary" to="/" className="mt-3 ml-4">
                <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                Back
            </Button>
            <Button onClick={saveNote} variant="primary" className="mt-3 ml-4">
                <FontAwesomeIcon icon={faSave} className="mr-2" />
                Save
            </Button>
        </>
    )
}
