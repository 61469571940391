import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

export default function NavbarComponent() {
    const { currentUser } = useAuth();

    return (
        <Navbar bg="dark" variant="dark" expand="xxl">
            <Navbar.Brand as={Link} to="/">
                {currentUser.displayName ? currentUser.displayName + "'s " : ''}Notes
            </Navbar.Brand>
            <Nav>
                <Nav.Link as={Link} to="/profile">
                    Profile
                </Nav.Link>
            </Nav>
        </Navbar>
    )
}
